import React from "react";
import { Link } from "gatsby";

export const Tile = ({
  title,
  description,
  target,
  tags,
  backgroundImageName,
  backgroundColorName
}) => (
  <div
    className="tile"
    style={
      backgroundImageName != null
        ? {
            backgroundImage: backgroundImageName
              ? "linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(" +
                backgroundImageName +
                ")"
              : "",
            backgroundSize: "cover"
          }
        : {
            background: backgroundColorName ? backgroundColorName : "black",
            backgroundSize: "cover"
          }
    }
  >
    <Link className="tile-link" to={target}>
      <div className="tile-wrapper">
        <div className="tile-tags">
          {tags.map((object, i) => (
            <div key={object} className="tile-tag">
              {object}
            </div>
          ))}
        </div>
        <div className="tile-title">{title}</div>
        <div className="tile-excerpt">{description}</div>
      </div>
    </Link>
  </div>
);
