import React from "react";
import { Layout } from "../../components/Layout";
import { Tile } from "../../components/Tile";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";

const Blog = ({ data }) => (
  <Layout>
    <SEO title="Blog" />
    <div className="page-title">
      <span>Blog</span>
    </div>
    <div className="page-content" style={{ gridAutoRows: "300px" }}>
      <Tile
        title="What's up with NFTs"
        description="Programmatically generating NFTs"
        target="/blog/nft-generator/"
        tags={["python", "nft"]}
        backgroundImageName={data.nftImageUrl.publicURL}
      />
    </div>
  </Layout>
);

export default Blog;

export const query = graphql`
  query getBlogImagePath {
    nftImageUrl: file(
      name: { eq: "nft-crop" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
  }
`;
